<template>
  <div>
    <div class="pt-5">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div style="transform: rotate(-0.25turn);">
            <b-img src="@/assets/images/exxtra/exxtra-logo.png" width="70px" fluid alt="exxtra-logo" />
          </div>
        </div>
      </div>
    </div>

    <!--     hero section-->
    <section>
      <div class="h-100 d-md-flex align-items-center justify-content-around">
        <div>
          <div class="mx-auto w-75">
            <h1 class="d-none d-md-block display-1 font-weight-bold">
              Hi there, <br>
              Have you heard
              of NSS Exxtra ?
            </h1>
            <h1 class="d-md-none font-weight-bold">
              Hi there, <br>
              Have you heard
              of NSS Exxtra ?
            </h1>
            <div>
              <div class="d-flex align-items-center">
                <div class="mr-2">

                  <Arrow />
                </div>
                <div>
                  <p class="m-0 p-0">
                    Scroll down to find out more
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <b-img src="@/assets/images/exxtra/mancup3d.png" alt="mancup-exxtra" fluid />
        </div>
      </div>
    </section>

    <!-- nss exxtra    -->
    <section>
      <div class="h-100 d-md-flex align-items-center justify-content-around">
        <div class="mb-3">
          <div class="mx-auto w-75">
            <h1 class="display-3 font-weight-bold">
              NSS Exxtra
            </h1>
            <div>
                <p class="lead">
                  Starting off after school can be tough and that’s why we are here to help. Nss Exxtra provides Nss personnel’s with loans that will help them acquire basic necessities to make life during service easy for them.
                </p>
            </div>
            <div class="d-flex">
              <div class="mr-2">
                <b-link :to="{ name: 'kickstart-collect-data'}">
                  <b-button class="secondary-bg">
                    Sign Up for Exxtra Now
                  </b-button>
                </b-link>
              </div>
              <div>
                <b-link :to="{ name: 'kickstart-collect-data'}">
                  <b-button class="secondary-bg">
                    Apply for Ezwich
                  </b-button>
                </b-link>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="mx-auto w-75">
            <p class="lead">
              Whether you need cash for transportation, clothing or for a small
              business start-up, working capital or to help pay for your rent during your National
              Service, NSS Exxtra is your best resort!

            </p>
          </div>
        </div>

      </div>
    </section>

    <!-- more benefits  -->
    <section>
      <div class="h-100 d-md-flex align-items-center justify-content-around">
        <div>
          <b-img src="@/assets/images/exxtra/MoreBenefits.png" fluid alt="more-benefits" />
        </div>
        <div>
          <div class="mx-auto w-75">
            <h1 class="display-3 font-weight-bold">
              More Benefits
            </h1>
            <div>
              <p class="lead">
                Whether you want to purchase a new machine or raw materials, young graduates can receive working capital or startup loans for their business needs.

              </p>
            </div>
          </div>
        </div>

      </div>
    </section>

    <!--     Ezwich -->
    <section>
      <div class="h-100 d-md-flex align-items-center justify-content-around">
        <div>
          <div class="mx-auto w-75">
            <h1 class="display-3 font-weight-bold">
              E-zwich
            </h1>
            <div>
              <p class="lead">
                We care about you, so we are collaborating with “this bank” to help you
                acquire your e-zwich card with ease.
              </p>
            </div>
            <div>
              <b-link :to="{ name: 'kickstart-collect-data'}">
                <b-button class="secondary-bg">
                  Apply for Ezwich
                </b-button>
              </b-link>
            </div>
          </div>
        </div>

        <div>
          <div>
            <b-img src="@/assets/images/exxtra/EzwichCard.png" fluid alt="more-benefits" />
          </div>
        </div>

      </div>
    </section>

    <!--     Footer -->
    <section>
      <div class="h-100 d-md-flex align-items-center justify-content-center">
        <div>
          <div class="position-relative text-center">
            <FooterBgn />
            <div class="centered secondary-bg">
              <div class="d-md-flex secondary-bg justify-content-center align-items-center">
                <div class="secondary-bg mr-1">
                  <h3 class="secondary-bg m-0 p-0">
                    Sign Up for NSS Exxtra
                  </h3>
                </div>
                <div class="secondary-bg">
                  <b-link :to="{ name: 'kickstart-collect-data'}">
                    <b-button variant="primary">
                      Nss Exxtra
                    </b-button>
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center py-5">
        <h1 class="">
          © 2022 CrownCity Technologies.

        </h1>
      </div>
      <div class="text-center pb-5">
        <div class="d-flex align-items-center justify-content-center">
          <div class="mx-2">
            <a href="https://instagram.com/exxtra.app"
               target="_blank"
               rel="noopener noreferrer"
            >
              <Instagram />
            </a>
          </div>
          <div class="mx-2">
            <a
                href="https://twitter.com/TurtleRent"
                target="_blank"
                rel="noopener noreferrer"
            >
              <Twitter />
            </a>
          </div>
          <div class="mx-2">
            <a href="https://facebook.com/exxtragh"
               target="_blank"
               rel="noopener noreferrer"
            >
              <Facebook />
            </a>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { BButton, BLink, BImg } from "bootstrap-vue";
import Ezwich from "@/assets/svg/Ezwich.svg"
import Human3D1 from "@/assets/svg/Human3D1.svg";
import Twitter from '@/assets/svg/Twitter.svg';
import Header from "@/pages/public/header.vue";
import Facebook from '@/assets/svg/Facebook.svg';
import Arrow from '@/assets/svg/Arrow.svg';
import FooterBgn from "@/assets/svg/FooterBgn.svg"
import Instagram from '@/assets/svg/Instagram.svg';

export default {
  name: "KickStart",
  components: {
    Header,
    BLink,
    BImg,
    BButton,
    Ezwich,
    Arrow,
    FooterBgn,
    Instagram,
    Human3D1,
    Twitter,
    Facebook
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-kickstart.scss';

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>
